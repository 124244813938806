import { IInitiateRecoveryRequest } from "@finbackoffice/clientbff-client";
import { TranslationScopes } from "@finbackoffice/enums";
import { RequestError } from "@finbackoffice/fe-core";
import {
    useRuntimeConfig,
    ClientBFFContext,
    useTranslation,
    userInfoValidationSchema,
} from "@finbackoffice/site-core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useContext, useState, useCallback, useEffect } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useForm, Controller, FieldError } from "react-hook-form";
import { InferType, string } from "yup";
import Button from "components/base/button/Button";
import PhoneInput from "components/base/phone-input/PhoneInput";
import Translate from "components/base/translate/Translate";
import Input from "components/base/input-field/Input";
import styles from "./forgot-pass.module.sass";

type IForgotPassForm = InferType<ReturnType<typeof getForgotPassStep1NoSwitchValidationSchema>>;

const getForgotPassStep1NoSwitchValidationSchema = () =>
    userInfoValidationSchema.pick(["email", "phone"]).shape({
        code: string()
            .default("")
            .when("$isCodeSent", ([isCodeSent], schema: any) => {
                if (isCodeSent) {
                    return schema.required().min(6);
                }
                return;
            }),
    });

type IProps = {
    setTempId: (token: string) => void;
    setStep: (val: number) => void;
    setCode: (val: string) => void;
    id: string;
};

const Step1NoSwitch: FC<IProps> = ({ setTempId, setStep, id, setCode }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const emailEnabled = COMMON_SITE_CONFIGS.forgotPassMethods.email;
    const phoneEnabled = COMMON_SITE_CONFIGS.forgotPassMethods.phone;
    const client = useContext(ClientBFFContext);
    const { t } = useTranslation();
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isResend, setIsResend] = useState(false);
    const [validUntil, setValidUntil] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const forgotPassConfigObj = {
        email: { name: "email", required: false },
        phone: { name: "phone", required: false },
    };

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        getValues,
        formState: { isValid, isSubmitting, errors, isDirty, dirtyFields },
    } = useForm({
        mode: "onChange",
        shouldUnregister: true,
        context: {
            isCodeSent,
            configObj: {
                ...forgotPassConfigObj,
                t,
            },
        },
        resolver: yupResolver(getForgotPassStep1NoSwitchValidationSchema()),
    });

    useEffect(() => {
        reset({ ...getValues() }, { keepDirty: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCodeSent]);

    const initiateRecovery = useCallback(
        async (data: IInitiateRecoveryRequest) => {
            if (data.login) {
                setLoading(true);
                try {
                    const response = await client.initiateRecovery(data);

                    setTempId(response.token.id);
                    setValidUntil(new Date(response.token.valid_until.toString()).getTime());
                    setIsCodeSent(true);

                    setLoading(false);
                } catch (err: any) {
                    const error: RequestError = err.response?.data;
                    if (error.error === "not_found") {
                        setError("email", {
                            type: "404",
                            message: t("forgot_password_error_userNotFound"),
                        });
                    }

                    setLoading(false);
                }
            }
        },
        [client, setError, setTempId, t],
    );

    const validateCode = useCallback(
        async (data: IForgotPassForm) => {
            setLoading(true);
            try {
                const response = await client.validateRecoveryToken({
                    code: data.code,
                    id,
                });

                setCode(data.code);
                setTempId(response.id);
                setStep(2);
            } catch (err: any) {
                const error: RequestError = err.response?.data;
                if (error.error === "recovery_error") {
                    setError("code", {
                        type: "400",
                        message: t("forgot_password_error_wrongCode"),
                    });
                }
                setLoading(false);
            }
        },
        [client, id, setCode, setError, setStep, setTempId, t],
    );

    const renderContent = useCallback(({ minutes, seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return null;
        }

        return (
            <span>
                <Translate
                    tid="forgot_password_new_code_resend"
                    replace={{
                        minutes,
                        seconds,
                    }}
                />
            </span>
        );
    }, []);

    const onCompleteHandler = useCallback(() => {
        setIsCodeSent(false);
        setIsResend(true);
        reset({ ...getValues(), code: undefined }, { keepDirty: true });
        setTempId("");
    }, [getValues, reset, setTempId]);

    const onSubmit = useCallback(
        (data: IForgotPassForm) => {
            const convertToRequestObj = (fields: IForgotPassForm): IInitiateRecoveryRequest => {
                const temp = { ...fields };
                return {
                    login: temp.email || temp.phone || "",
                };
            };

            if (isDirty && isValid && !isSubmitting && !loading) {
                let changedFields: IForgotPassForm = {} as IForgotPassForm;
                Object.keys(dirtyFields).forEach((field) => {
                    changedFields = {
                        ...changedFields,
                        [field]: data[field as keyof IForgotPassForm],
                    };
                });

                if (isCodeSent) {
                    validateCode(data);
                } else {
                    initiateRecovery(convertToRequestObj(changedFields));
                }
            }
        },
        [
            isDirty,
            isValid,
            isSubmitting,
            loading,
            dirtyFields,
            isCodeSent,
            validateCode,
            initiateRecovery,
        ],
    );

    useEffect(() => {
        setIsCodeSent(false);
        setIsResend(false);
        reset();
        setTempId("");
    }, [reset, setTempId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p>
                <Translate tid="cpf_recover_account_text" namespace={TranslationScopes.Cpf} />
            </p>
            {emailEnabled && (
                <Controller
                    render={({ field: { onChange, value, name } }) => {
                        return (
                            <Input
                                onChange={onChange}
                                value={value}
                                name={name}
                                label={t("forgot_password_email")}
                                error={errors.email}
                                disabled={isCodeSent}
                            />
                        );
                    }}
                    control={control}
                    name="email"
                />
            )}
            {emailEnabled && phoneEnabled && (
                <div className={styles.orTxt}>
                    <Translate tid="cpf_recover_or" namespace={TranslationScopes.Cpf} />
                </div>
            )}
            {phoneEnabled && (
                <Controller
                    render={({ field: { onChange, value } }) => (
                        <PhoneInput
                            label="userData_mobile"
                            onChange={onChange}
                            disabled={isCodeSent}
                            value={value ?? ""}
                            separateDialCode
                            setError={setError}
                            clearErrors={clearErrors}
                            error={errors.phone || (errors.root?.apiError as FieldError)}
                        />
                    )}
                    control={control}
                    name="phone"
                />
            )}

            <span>
                <Translate tid="forgot_password_enter_code" />
            </span>
            <div className={styles.forgotPassField}>
                <Controller
                    render={({ field: { onChange, value, name } }) => {
                        return (
                            <Input
                                type="number"
                                valueAsNumber={false}
                                error={errors.code}
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeholder="123456"
                                disabled={!isCodeSent}
                            />
                        );
                    }}
                    control={control}
                    name="code"
                />
                {isCodeSent ? (
                    <Button
                        type="submit"
                        disabled={
                            !isDirty ||
                            !isValid ||
                            !!errors.root?.apiError ||
                            isSubmitting ||
                            loading
                        }
                        variant="secondary">
                        <Translate tid="forgot_password_next" />
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        disabled={
                            !isDirty ||
                            !isValid ||
                            !!errors.root?.apiError ||
                            isSubmitting ||
                            loading
                        }
                        variant="thirdary">
                        {isResend ? (
                            <Translate tid="forgot_password_resend_code" />
                        ) : (
                            <Translate tid="forgot_password_request_code" />
                        )}
                    </Button>
                )}
            </div>
            {isCodeSent && !errors.root?.apiError && validUntil && (
                <Countdown
                    date={validUntil}
                    renderer={renderContent}
                    onComplete={onCompleteHandler}
                />
            )}
        </form>
    );
};

export default Step1NoSwitch;
